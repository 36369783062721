import { Component, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from '../../providers/auth.service';
import { User } from '../../models/User';
import { Router } from '@angular/router';
import { slideInDownAnimation } from '../../../core/animations/animations';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [slideInDownAnimation]
})
export class RegisterComponent implements OnInit {
  @HostBinding('@routeAnimation') routeAnimation = true;
  public form: FormGroup;
  get name() { return this.form.get('name'); }
  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }
  get passwordCheck() { return this.form.get('passwordCheck'); }
  constructor(private fireStore: AngularFirestore, private authService: AuthService, private router: Router) { }

  public ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.minLength(6), Validators.required]),
      passwordCheck: new FormControl('', [Validators.minLength(6), Validators.required, this.passwordMatchValidator.bind(this)])
    });
  }

  public register(): void {
    this.authService.createAccount(this.email.value, this.password.value)
      .then((s) => {
        const user: User = { name: this.name.value, email: this.email.value };
        this.fireStore.collection('users').doc(s.user.uid).set(user)
          .then((su) => {
            this.authService.signIn(this.email.value, this.password.value).then((suc) => this.router.navigate(['/account/suggestionlist']))
              .catch((err) => console.log(err));
          })
          .catch((er) => { console.log(s); });
      })
      .then((e) => { console.log(e); });
  }

  public passwordMatchValidator() {
    if (this.form !== undefined) {
      if (this.form.get('password').value === this.form.get('passwordCheck').value) {
        return null;
      } else {
        return { 'doesMatchPassword': true };
      }
    }
  }
}
