import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AngularFirestore } from 'angularfire2/firestore';
import * as moment from 'moment';
import { AuthService } from '../../../authentication/providers/auth.service';
import { slideInDownAnimation } from '../../../core/animations/animations';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-add-suggestion',
  templateUrl: './add-suggestion.component.html',
  styleUrls: ['./add-suggestion.component.scss'],
  animations: [slideInDownAnimation]
})
export class AddSuggestionComponent implements OnInit {
  @HostBinding('@routeAnimation') routeAnimation = true;
  public name: string;
  public form = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(6)]),
    description: new FormControl(''),
    link: new FormControl(''),
  });
  get title() { return this.form.get('title'); }
  get description() { return this.form.get('description'); }
  get link() { return this.form.get('link'); }

  constructor(private firestore: AngularFirestore, private authService: AuthService, private dialog: MatDialog) { }

  public ngOnInit(): void {
    this.authService.isAuthenticated().subscribe((e) => {
      this.firestore.collection('users').doc(e.uid).valueChanges().subscribe((n: any) => { this.name = n.name; });
    });
  }

  public create(): void {
    const now: moment.Moment = moment();
    const uid = this.firestore.createId();
    const sugestion = {
      id: uid,
      title: this.title.value,
      description: this.description.value,
      link: this.link.value,
      createdTime: this.buildDate(now),
      createdBy: this.name,
      likesCount: 0,
      likedBy: [],
      hide: false
    };
    this.firestore.collection('suggestions').doc(uid).set(sugestion)
      .then((s) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          data: { text: 'Dit forslag er nu oprettet' },
        });
        this.link.reset();
        this.title.reset();
        this.description.reset();
      })
      .catch((e) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          data: { text: 'Der skete en fej: ' + e },
        });
      });
  }

  private buildDate(momentObject: moment.Moment): string {
    const day = momentObject.date();
    const month = momentObject.month() + 1;
    const year = moment().year();
    return `${day}-${month}-${year}`;
  }
}
