import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInDownAnimation =
  trigger('routeAnimation', [
    state('in', style({opacity: 1, transform: 'translateX(0)'})),
    transition('void => *', [
      style({
        opacity: 0,
        transform: 'translateX(-100%)'
      }),
      animate('0.8s ease-in')
    ]),
    transition('* => void', [
      animate('0.8s 0.5s ease-out', style({
        opacity: 0,
        transform: 'translateX(100%)'
      }))
    ])
  ]);

