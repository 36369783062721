import { Component, OnInit, HostBinding } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { slideInDownAnimation } from '../../../core/animations/animations';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [slideInDownAnimation]
})
export class LoginComponent implements OnInit {
    @HostBinding('@routeAnimation') routeAnimation = true;
    public errorMessage = '';
    public form = new FormGroup({
        email: new FormControl('', [Validators.email, Validators.required]),
        password: new FormControl('', [Validators.minLength(6), Validators.required])
    });

    get email() { return this.form.get('email'); }
    get password() { return this.form.get('password'); }

    constructor(private router: Router, private authService: AuthService) { }

    public ngOnInit(): void {

    }
    public signIn(): void {
        this.authService.signIn(this.email.value, this.password.value)
            .then((s) => { this.router.navigate(['account/suggestionlist']); console.log(s); })
            .catch((e) => {
                this.determineError(e.code);
            });
    }

    private determineError(code: string): void {
        switch (code) {
            case 'auth/wrong-password':
                this.errorMessage = 'forkert password';
                break;
            case 'auth/user-not-found':
                this.errorMessage = 'bruger findes ikke';
                break;
            case 'auth/email-already-in-use':
                this.errorMessage = 'email er allerede i brug';
                break;
            case 'auth/argument-error':
                this.errorMessage = 'email feltet skal være udfyldt';
                break;
            case 'auth/invalid-email':
                this.errorMessage = 'email er ikke korrekt';
                break;
            default:
                this.errorMessage = 'Der skete en fejl. Kontakt admin';
                break;
        }
    }
}
