import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { AddSuggestionComponent } from './pages/add-suggestion/add-suggestion.component';
import { HomeComponent } from './pages/home/home.component';
import { SuggestionListComponent } from './pages/suggestion-list/suggestion-list.component';
import { ThreeDPrintListComponent } from './pages/three-d-print-list/three-d-print-list.component';

const routes: Routes = [
  {
    path: 'account', component: HomeComponent, canActivate: [AuthGuard],
    children: [
        { path: 'addsuggestion', component: AddSuggestionComponent, canActivate: [AuthGuard] },
        { path: 'suggestionlist', component: SuggestionListComponent, canActivate: [AuthGuard] },
        { path: '3dprintlist', component: ThreeDPrintListComponent, canActivate: [AuthGuard] },
        // { path: 'habits', component: HabitsComponent, canActivate: [AuthGuard] },
        // { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },
    ],
}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
