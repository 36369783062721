import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
import { User } from '@firebase/auth-types';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private fireAuth: AngularFireAuth) {}
  /**
   * Signs a user in with email and password
   */
  public signIn(email: string, password: string): Promise<any> {
    return this.fireAuth.auth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Sings a user out if user is signed in
   */
  public signOut(): Promise<any> {
    if (this.fireAuth.authState) {
      return this.fireAuth.auth.signOut();
    }
  }

  public createAccount(email: string, password: string): Promise<any> {
    return this.fireAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  /**
   * Checks if user is authenticated
   */
  public isAuthenticated(): Observable<any> {
    return this.fireAuth.authState;
  }
}
