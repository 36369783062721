import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import {
  MatGridListModule, MatCardModule, MatMenuModule, MatIconModule, MatButtonModule,
  MatToolbarModule, MatSidenavModule, MatListModule, MatDatepickerModule,
  MatFormFieldModule, MatInputModule, MatDatepicker, DateAdapter, MAT_DATE_LOCALE,
  MAT_DATE_FORMATS, MatNativeDateModule, MatTableModule, MatTooltipModule
} from '@angular/material';
import { HomeComponent } from './pages/home/home.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AddSuggestionComponent } from './pages/add-suggestion/add-suggestion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SuggestionListComponent } from './pages/suggestion-list/suggestion-list.component';
import { ConfirmationDialogComponent } from './pages/confirmation-dialog/confirmation-dialog.component';
import { ThreeDPrintListComponent } from './pages/three-d-print-list/three-d-print-list.component';
@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatTooltipModule,
  ],
  declarations: [HomeComponent, AddSuggestionComponent, SuggestionListComponent, ConfirmationDialogComponent, ThreeDPrintListComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  entryComponents: [ConfirmationDialogComponent]
})
export class AccountModule { }
