import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { Subscription } from 'rxjs';
import { slideInDownAnimation } from '../../../core/animations/animations';

@Component({
  selector: 'app-suggestion-list',
  templateUrl: './suggestion-list.component.html',
  styleUrls: ['./suggestion-list.component.scss'],
  animations: [slideInDownAnimation]
})
export class SuggestionListComponent implements OnInit, OnDestroy {
  @HostBinding('@routeAnimation') routeAnimation = true;
  public displayedColumns = ['titel', 'beskrivelse', 'link', 'oprettet', 'oprettetaf', 'likes', 'button'];
  public dataSource: MatTableDataSource<any>;
  public hasVoted = false;
  public uid: string;
  private subscription: Subscription;
  constructor(private fireStore: AngularFirestore, private auth: AngularFireAuth) {
    this.auth.user.subscribe((user) => {
      this.uid = user.uid;
    });
  }

  public ngOnInit(): void {
    this.fireStore.collection('suggestions').valueChanges().subscribe((sub) => {
      const suggestions = sub.filter((u: any) => u.hide === false).sort((left: any, right: any): number => {
        if (left.likesCount > right.likesCount) { return -1; }
        if (left.likesCount < right.likesCount) { return 1; }
      });
      this.dataSource = new MatTableDataSource(suggestions);
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public like(sub: any): void {
    if (!sub.likedBy.includes(this.uid)) {
      sub.likedBy.push(this.uid);
      sub.likesCount = sub.likedBy.length;
      this.fireStore.collection('suggestions').doc(sub.id).set(sub);
    } else {
      const index = sub.likedBy.indexOf(this.uid, 0);
      if (index > -1) {
        sub.likedBy.splice(index, 1);
        sub.likesCount = sub.likedBy.length;
        this.fireStore.collection('suggestions').doc(sub.id).set(sub);
      }
    }
  }
}
