import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AngularFireAuth } from 'angularfire2/auth/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/authentication/providers/auth.service';
import { slideInDownAnimation } from 'src/app/core/animations/animations';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-three-d-print-list',
  templateUrl: './three-d-print-list.component.html',
  styleUrls: ['./three-d-print-list.component.scss'],
  animations: [slideInDownAnimation]
})
export class ThreeDPrintListComponent implements OnInit, OnDestroy {
  @HostBinding('@routeAnimation') routeAnimation = true;
  public displayedColumns = ['name', 'link', 'comment', 'avgTime', 'started', 'button1', 'button2'];
  public name: string;
  private position: number;
  public dataSource: MatTableDataSource<any>;
  public form = new FormGroup({
    link: new FormControl(''),
    avgTime: new FormControl('', [Validators.required, Validators.minLength(1), Validators.pattern('[0-9,]+')]),
    comment: new FormControl('')
  });
  get link() { return this.form.get('link'); }
  get avgTime() { return this.form.get('avgTime'); }
  get comment() { return this.form.get('comment'); }
  private subscription: Subscription;
  private printList: any;
  public ownerId: any;
  constructor(private fireStore: AngularFirestore, private auth: AngularFireAuth, private dialog: MatDialog,
    private authService: AuthService) { }

  public ngOnInit(): void {
    this.subscription = this.fireStore.collection('threedprintlist').valueChanges().subscribe((sub: any) => {
      this.position = sub.length;
      this.printList = sub.filter(x => !x.done).sort((left: any, right: any): number => {
        if (left.position > right.position) { return 1; }
        if (left.position < right.position) { return -1; }
      });
      this.dataSource = new MatTableDataSource(this.printList);
    });
    this.authService.isAuthenticated().subscribe(user => {
      this.ownerId = user.uid;
    });
    this.authService.isAuthenticated().subscribe((e) => {
      this.fireStore.collection('users').doc(e.uid).valueChanges().subscribe((n: any) => { this.name = n.name; });
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public create(): void {
    const uid = this.fireStore.createId();
    const user = {
      id: uid,
      name: this.name,
      link: this.link.value,
      comment: this.comment.value,
      avgTime: this.avgTime.value,
      ownerId: this.ownerId,
      started: false,
      startedAt: '',
      done: false,
      position: this.position + 1,
    };
    this.fireStore.collection('threedprintlist').doc(uid).set(user)
      .then((s) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          data: { text: 'Du er tilføjet til køen' },
        });
        this.form.reset();
      })
      .catch((e) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          data: { text: 'Der skete en fej: ' + e },
        });
      });
  }

  public start(sub: any): void {
    const now: moment.Moment = moment();
    sub.started = true;
    sub.startedAt = this.buildDate(now);
    this.fireStore.collection('threedprintlist').doc(sub.id).set(sub);
  }

  public done(sub: any): void {
    sub.done = true;
    this.fireStore.collection('threedprintlist').doc(sub.id).set(sub)
      .then(() => { })
      .catch((err) => console.log(err));
  }

  private buildDate(momentObject: moment.Moment): string {
    const hour = momentObject.hours();
    const min = momentObject.minutes();
    const secund = momentObject.seconds();
    const day = momentObject.date();
    const month = momentObject.month() + 1;
    const year = moment().year();
    return `${day}-${month}-${year} ${this.setTime(hour)}:${this.setTime(min)}:${this.setTime(secund)}`;
  }

  private setTime(min: any): any {
    if (min <= 9) {
      return '0' + min;
    } else {
      return min;
    }
  }
}
